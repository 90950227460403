@use "@angular/material" as mat;
/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
// @import "./assets/plugins/keenicons/duotone/style.css";
// @import "./assets/plugins/keenicons/outline/style.css";
// @import "./assets/plugins/keenicons/solid/style.css";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "../node_modules/ng-zorro-antd/style/index.min.css";

// @include mat.all-component-densities(minimum);


@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Italic-VariableFont_opsz,wght.ttf') format('truetype');
  //font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
  //font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Roboto-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  //font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Roboto-VariableFont_wdth,wght.ttf') format('truetype');
  //font-weight: 100 900;
  font-style: normal;
}

:root {
  --bs-primary: #ea3739 !important;
  --bs-primary-active: #ea3739 !important;
  --bs-text-primary: #ea3739 !important;
}


html,
body {
  height: 100%;
  scrollbar-width: none;
}

body {
  margin: 0;
  background: #fff !important;
  font-family: Roboto, "Inter", "Helvetica Neue", sans-serif; 
  //font-family: "Inter",Helvetica,"sans-serif" !important;

  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}




// mat-form-field .mat-mdc-text-field-wrapper {
//     height: 40px;
// }

// mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
//     top: 19px;
//     font-size: 14px;
// }

// mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex input::placeholder {
//     font-size: 14px;
// }

// mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
// [dir="rtl"] .mat-mdc-form-field-icon-suffix {
//     height: 8px;
//     font-size: 14px;
//     padding: 0px 4px 0px 4px;
// }

// mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
//     padding: 0px 4px 8px 4px !important;
// }

// mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
//     padding-top: 10px;
// }

// mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix .mat-mdc-select-value-text {
//     font-size: 14px;
// }

mat-error {
  font-size: 12px !important;
}

.row {
  margin-left: 0;
  margin-right: 0;
}


.material-icons {
  font-size: 17px;
}

mat-datepicker-toggle .mat-mdc-icon-button {
  padding: 0 !important;
  height: 0 !important;
}

// .mat-mdc-button.mat-mdc-button-base,
// .mat-mdc-raised-button.mat-mdc-button-base,
// .mat-mdc-unelevated-button.mat-mdc-button-base,
// .mat-mdc-outlined-button.mat-mdc-button-base {
//     min-width: 40px;
// }
// .mat-mdc-form-field-icon-prefix {
//     padding: 0 6px 12px 6px !important;
// }

.mat-datepicker-toggle .mat-mdc-icon-button {
  padding: 0 !important;
  height: 0 !important;
}

.mat-form-field-fluid {
  width: 100%;
  @include mat.form-field-density(minimum);

  .mat-mdc-select-value-text {
    font-size: 14px !important;
  }

  .mat-mdc-input-element {
    font-size: 14px !important;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }


}

.detail-popup .mdc-list-item__primary-text{
  padding: 8px 0px !important;
  border-bottom: 1px solid #e7e7e7 !important;
}

body.swal2-height-auto {
  height: 100% !important;
}

.anticon {
  display: inline-flex !important;
}

.active-link {
  border-bottom-width: 2px !important;
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 2px solid var(--bs-primary) !important;
  transition: color 0.2s ease !important;
}

.text-right {
  text-align: right !important;
}

.kt-visible-desktop,
.kt-visible-desktop-inline,
.kt-visible-desktop-inline-block,
.kt-visible-desktop-table,
.kt-visible-desktop-table-cell,
.kt-visible-mobile,
.kt-visible-mobile-inline,
.kt-visible-mobile-inline-block,
.kt-visible-mobile-table,
.kt-visible-mobile-table-cell,
.kt-visible-tablet,
.kt-visible-tablet-and-mobile,
.kt-visible-tablet-and-mobile-inline,
.kt-visible-tablet-and-mobile-inline-block,
.kt-visible-tablet-and-mobile-table,
.kt-visible-tablet-and-mobile-table-cell,
.kt-visible-tablet-inline,
.kt-visible-tablet-inline-block,
.kt-visible-tablet-table,
.kt-visible-tablet-table-cell {
  display: none !important
}

@media (min-width:1025px) {
  .kt-visible-desktop {
    display: block !important
  }

  .kt-visible-desktop-inline-block {
    display: inline-block !important
  }

  .kt-visible-desktop-inline {
    display: inline !important
  }

  .kt-visible-desktop-table {
    display: table !important
  }

  .kt-visible-desktop-table-cell {
    display: table-cell !important
  }

  .kt-hidden-desktop {
    display: none !important
  }
}

@media (min-width:769px) and (max-width:1024px) {
  .kt-visible-tablet {
    display: block !important
  }

  .kt-visible-tablet-inline-block {
    display: inline-block !important
  }

  .kt-visible-tablet-inline {
    display: inline !important
  }

  .kt-visible-tablet-table {
    display: table !important
  }

  .kt-visible-tablet-table-cell {
    display: table-cell !important
  }

  .kt-hidden-tablet {
    display: none !important
  }
}

@media (max-width:1024px) {
  .kt-visible-tablet-and-mobile {
    display: block !important
  }

  .kt-visible-tablet-and-mobile-inline-block {
    display: inline-block !important
  }

  .kt-visible-tablet-and-mobile-inline {
    display: inline !important
  }

  .kt-visible-tablet-and-mobile-table {
    display: table !important
  }

  .kt-visible-tablet-and-mobile-table-cell {
    display: table-cell !important
  }

  .kt-hidden-tablet-and-mobile {
    display: none !important
  }
}

@media (max-width:768px) {
  .kt-visible-mobile {
    display: flex !important
  }

  .kt-visible-mobile-inline-block {
    display: inline-block !important
  }

  .kt-visible-mobile-inline {
    display: inline !important
  }

  .kt-visible-mobile-table {
    display: table !important
  }

  .kt-visible-mobile-table-cell {
    display: table-cell !important
  }

  .kt-hidden-mobile {
    display: none !important
  }
}

.label {
  font-weight: 450;
  font-size: 1.075rem;
}

@media (max-width: 768px) {

  .mat-mdc-unelevated-button>.mat-icon,
  .mat-mdc-raised-button>.mat-icon,
  .mat-mdc-outlined-button>.mat-icon {
    margin-right: inherit !important;
  }
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loader .ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
  position: fixed !important;
  top: 55% !important;
  padding-top: 0 !important;
  left: 0.4% !important;
}

.popup-backdrop {
  background: rgba(0, 0, 0, 0.80) !important;
}

@media (max-width: 600px) {
  .ant-picker {
    border-radius: .475rem !important;
    padding: .775rem 1rem !important;
  }

  .ant-switch-checked {
    background: #FFC9CD;
  }

  .ant-switch-handle::before {
    background-color: #ED3237;
  }
}

.ant-picker {
  width: 100%
}

.fa-lg {
  font-size: 1.25em !important;
}

.cdk-overlay-container {
  .custom-snackbar {
    width: fit-content !important;

    .mdc-snackbar__surface {
      width: fit-content !important;
      min-width: 0 !important;
    }

    .mdc-snackbar__label {
      font-size: 14px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      text-align: center !important;
    }
  }
}

.cdk-overlay-container {
  .custom-snackbar-center {
    width: fit-content !important;
    position: fixed !important;
    top: 55% !important;
    transform: translateY(-50%) !important;
    left: 25% !important;
    transform: translate(-50%, -50%);

    .mdc-snackbar__surface {
      width: fit-content !important;
      min-width: 0 !important;
    }

    .mdc-snackbar__label {
      font-size: 14px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      text-align: center !important;
    }
  }
}






// header section tart

.mobile-header {
  background: #fff;
  position: sticky;
  top: 0;
  // padding-bottom: 13px;
}

// header section end


// footer section start

.mobile-footer .section {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mobile-footer .section .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 10px 0 22px;
}

.mobile-footer .section .item .pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}

.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #FFF;
  box-shadow: 0 -4px 8px #00000014;
}

.mobile-footer .section .item svg {
  width: 22px;
}

.mobile-footer .section .item .spotlight-img {
  width: auto;
}


.mobile-footer .section .item .foot {
  width: 26px;
}

.mobile-footer .section .item svg path {
  fill: #616161;
}


.mobile-footer .section .item.active svg path {
  fill: #E93739;
}

.mobile-footer .section .item.active .text {
  color: #E93739;
  font-weight: 500;
}

.mobile-footer .section .item {
  border-top: 3px solid transparent;
}

.mobile-footer .section .item.active {
  border-top: 3px solid #E93739;
}

.mobile-footer .section .item.active .text {
  color: #E93739;
  font-weight: 500;
}


.mobile-footer .section .item .text {
  color: #616161;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

// footer  section end


// default start

.big-card {
  margin: 7px;
  border-radius: 12px;
  background: #e8e8e8;
  display: flex;
  gap: 10px;
  padding: 0 8px 10px;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  background-image: url(../src/assets/media/svg/yes-icons/menu-icon/bg-event.png);
  background-position: top;
  background-size: cover;
  // box-shadow: #0000000d 0 4px 10px;
}



.primary-btn {
  background-color: #E93739;
  border: 1px solid #E93739;
  padding: 8px 15px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: self-end;
}

.primary-btn span {
  color: #fff;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}

.primary-btn img {
  width: 20px;
}


.secondary-btn {
  background-color: #fff;
  border: 1px solid #E93739;
  padding: 8px 15px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: self-end;
}

.secondary-btn span {
  color: #E93739;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}

.secondary-btn img {
  width: 20px;
}

// default end


// custom style start

.parent .div {
  display: inline-block;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.parent .mar {
  font-size: 12px;
  color: #000;
  line-height: normal;
}

.parent {
  // position: absolute;
  // top: 0;
  // left: 0;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0px 0px 6px 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  text-align: center;
  font-size: var(--font-size-9xl);
}




.parent1 {
  border-radius: 0 0 6px 6px;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  text-align: center;
  font-size: var(--font-size-9xl);
}

.parent1 .div {
  display: inline-block;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.parent1 .mar {
  font-size: 12px;
  color: #000;
}


.union-icon {
  width: 16px;
  filter: grayscale(1);
  opacity: 0.5;
}

.union-parent {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 10px;
}


// custom style end




// Common Title CSS Start

.common-title {
  color: #000;
  // text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  line-height: normal;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.my-meeting-dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 7px;
  height: 7px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0px 2px;
  padding: 0px;
  cursor: pointer;
}

.dot.active {
  width: 20px;
  border-radius: 10px;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #232020;
}



// .nav-line-tabs.nav-line-tabs-2x {
//   border-bottom: 2px solid #dcdcdc !important;
// }
// .nav-line-tabs.nav-line-tabs-2x .nav-item {
//   width: 50% !important;
// }


// Common Title CSS End

.frame-parent {
  // padding-top: 15px;
  background: #fff;
  // border-radius: 25px 25px 0 0;
  // position: relative;
  // bottom: 15px;
  // z-index: 1;
}

app-qr-code-popup .mat-mdc-dialog-content {
  padding: 0 !important;
}

app-add-volunteer-popup .mat-mdc-dialog-content {
  padding: 0 !important;
}

app-add-guest-popup .mat-mdc-dialog-content {
  padding: 0 !important;
}

app-add-spotlight-member-popup .mat-mdc-dialog-content {
  padding: 0 !important;
}

.pre-line-option .mdc-list-item__primary-text {
  white-space: pre-line !important;
}





// no record image start
.no-record-img {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

p.ant-empty-description {
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.ant-empty-image {
  height: 150px;
}

// no record image end







// yescon styles start

.yescon-layout {
  padding: 15px;
}

// yescon styles end

.pb-2px {
  padding-bottom: 2px !important;
}

p {
  word-break: break-all;
}


h1, h2, h3, h4, h5, h6 {
  word-break: break-all;
}

.frame-title {
  word-break: break-all;
}